<div [class]="type" class="notification-card-container" id="notification-card">
    <div class="icon-container">
        <div [class]="type" class="icon" id="notification-icon">i</div>
    </div>
    <div class="text-container" id="notification-container">
        <div class="h2-standard title" id="notification-title">
            {{ title || 'general.error.somethingWrong' | translate }}
        </div>
        <div class="n16-regular" id="notification-message">
            {{ description || 'general.message.contactSupport'  | translate }}
        </div>
    </div>
    @if(shouldShowCloseButton) {
        <button class="close-icon" (click)="close()" id="close-notification-button">
            <i class="far fa-xmark" aria-hidden="true" id="close-notification-icon"></i>
        </button>
    }
</div>
