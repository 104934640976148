export enum ContentType {
    Generic,
    Text,
    Image,
    File,
    Video,
    Typeform,
    Html5,
    Divider,
    Assessment
}

export enum LessonStatus {
    Draft = 1,
    Ready = 2,
    Published = 3,
    Archived = 4
}

export enum LearningStatus {
    NotStarted,
    InProgress,
    Completed
}

export enum LinkType {
    Link,
    Route
}

export enum NotificationType {
    Error = 'error',
    Warning = 'warning',
    Information = 'information'
}

export enum SortingDropdownOptions {
    LastProgress = 'learningCatalog.layout.searchingOptions.lastProgress',
    Newest = 'learningCatalog.layout.searchingOptions.newest',
    RecentUpdate = 'learningCatalog.layout.searchingOptions.recentUpdate',
    SortAscAlphabet = 'learningCatalog.layout.searchingOptions.alphabetical',
    SortDescAlphabet = 'learningCatalog.layout.searchingOptions.reverseAlphabetical'
}

export enum Roles {
    BasicAdmin,
    ContentEditor,
    FreeUser,
    FullAdmin,
    Instructor,
    LearningConsultant,
    LicenseHolder,
    Manager,
    Member,
    ReadAll,
    RevenueOperations,
    SuperAdmin,
    Support,
}

export enum JourneyType {
    None = 0, // Used for the standAlone lessons
    Module = 1,
    Course = 2,
    CertificateProgram = 3
}

export enum DifficultyLevel {
    Beginner = 1,
    Intermediate = 2,
    Advanced = 3
}

export enum JourneyElementType {
    Module = 1,
    Course = 2,
    CertificateProgram = 3,
    Lesson = 4
}

export enum JourneyStatus {
    Draft = 1,
    Ready = 2,
    Published = 3,
    Archived = 4
}

export enum RatingStatus {
    Dislike = -1,
    None = 0,
    Like = 1
}

export enum FilterName {
    HRDomain = 'HR Domain',
    Type = 'Type',
    Progress = 'Progress',
    Difficulty = 'Difficulty',
    Skill = 'Skill'
}
