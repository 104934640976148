<div class="nav-container" [hidden]="hideNavBar">
    <nav id="navigation-holder" class="navbar navbar-expand">
        <div class="container">
            <div class="navbar-toggler-container">
                @if(shouldShowSidenavMenu){
                    <button
                        class="navbar-toggler d-xl-none"
                        type="button"
                        data-toggle="collapse"
                        data-target="#sidenav-main"
                        aria-controls="sidenav-main"
                        aria-label="Toggle navigation"
                        [attr.aria-expanded]="!isCollapsed"
                        (click)="onToggleMenu()"
                        id="hamburger-menu-button"
                    >
                        <span class="navbar-toggler-icon">
                            <i aria-hidden="true" class="sidenav-toggler-line"></i>
                            <i aria-hidden="true" class="sidenav-toggler-line"></i>
                            <i aria-hidden="true" class="sidenav-toggler-line"></i>
                        </span>
                    </button>
                }
            </div>
            <div [class.is-download-active]="downloadItemsCount.total"
                 [class.is-onboarding-active]="shouldShowOnboardingComponent" class="app-logo"
                 id="header-logo-container">
                <a [routerLink]="['/']" class="app-logo-brand" id="logo-link">
                    <div class="logo" id="header-logo"></div>
                </a>
            </div>
            <div class="collapse navbar-collapse" id="navbarNav">
                <div class="download-container" id="download-container">
                    @if(downloadItemsCount.total) {
                        <button class="download-items-toggler" (click)="toggleDownloadContainer()"
                                id="toggle-download-button">
                            @if(downloadItemsCount.areRemainingItemsFailed){
                                <i class="fal fa-exclamation-triangle" aria-hidden="true" id="download-failed-icon"></i>
                            } @else if(downloadItemsCount.completed === downloadItemsCount.total){
                                <i class="fal fa-check-circle" aria-hidden="true" id="download-success-icon"></i>
                            } @else {
                                <i class="fal fa-circle-notch fa-spin" aria-hidden="true"
                                   id="download-loading-icon"></i>
                            }
                            <div class="n14-bold">
                                <span id="completed-downloads">{{ downloadItemsCount.completed }} </span>
                                <span class="counter-separator"></span>
                                <span id="total-downloads"> {{ downloadItemsCount.total }} </span>
                                <span class="counter-end"
                                      id="counter-end">{{ 'download.counterEnd' | translate }}</span>
                            </div>
                        </button>
                    }
                    <app-loading-items-container [shouldShowItems]="shouldShowDownloadItems" [downloadCounter]="downloadItemsCount"></app-loading-items-container>
                </div>
                @if(shouldShowOnboardingComponent && onboardingProgress !== 100){
                    <div class="onboarding-button-area" id="onboarding-button-area">
                        <button class="onboarding-button n14-bold" id="onboarding-button"
                                (click)="onboardingService.redirectToOnboardingPage()">
                            <span class="text-container">{{ 'account.onboarding' | translate }}</span>
                            <span class="separator"></span>
                            <span class="progress-percentage" id="progress-percentage">{{ onboardingProgress }}%</span>
                        </button>
                    </div>
                }
                <ul class="navbar-nav">
                    <li class="nav-item dropdown" *ngIf="authService.user$ | async as user">
                        <a
                            [matMenuTriggerFor]="menu"
                            class="nav-link dropdown-toggle"
                            id="profileDropDown"
                        >
                            <img
                                [src]="user.picture"
                                alt="User profile"
                                class="nav-user-profile rounded-circle navbar-image"
                                referrerpolicy="no-referrer"
                                id="profile-picture"
                            />
                        </a>
                        <mat-menu class="profile-dropdown" #menu="matMenu">
                            <div class="dropdown-title n14-regular" id="user-name">
                                {{ user.name }}
                            </div>
                            <a [href]="profileLink" class="dropdown-item n14-regular" id="profile-link" mat-menu-item>
                                <i class="fa-solid fa-user" id="profile-icon"></i>
                                {{ 'account.profile' | translate }}
                            </a>
                            <button (click)="logout()" class="btn btn-link dropdown-item n14-regular" id="logout-button"
                                    mat-menu-item>
                                <i class="fa-solid fa-power-off" id="logout-icon"></i>
                                {{ 'account.logout' | translate }}
                            </button>
                        </mat-menu>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>
<div class="navbar-loading-container">
    <mat-progress-bar mode="indeterminate" [color]="'accent'" *ngIf="isLoading"></mat-progress-bar>
</div>
